<template>
  <div class="PERInformation">
    <div class="PERInformation-head">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>活动管理</el-breadcrumb-item>
        <el-breadcrumb-item>选择机构</el-breadcrumb-item>
        <el-breadcrumb-item>机构课程选择</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 机构信息 -->
    <div class="organization">
      <div class="left">
        <h2>机构信息</h2>
        <img :src="id.mechanism_logo" alt="" />
      </div>
      <div class="right">
        <ul>
          <li>
            机构名称: <span>{{ id.mechanism_name }}</span>
          </li>
          <li>
            联系电话: <span>{{ id.mechanism_telephone }}</span>
          </li>
          <li>
            经营类别: <span>{{ id.categories_child }}</span>
          </li>
          <li>
            机构地址: <span>{{ id.mechanism_addr }}</span>
          </li>
          <li>
            支付宝账号: <span>{{ id.payee_logon_id }}</span>
          </li>
          <li>
            负责人姓名: <span>{{ id.contacts }}</span>
          </li>
          <li>
            负责人电话 :<span>{{ id.contact_telephone }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 机构营业执照 -->
    <div class="picture">
      <div class="picture1">
        <h2>机构营业执照</h2>
        <img :src="id.support_means" alt="" />
      </div>
      <div class="picture2">
        <h2>机构门面</h2>
        <img :src="id.facade_view" alt="" />
      </div>
      <div class="picture3">
        <h2>其他照片</h2>
        <div
          v-for="item in id.introduction_pic.split(',')"
          :key="item"
          style="min-width:200px，min-height:200px"
        >
          <img :src="item" alt="" />
        </div>
      </div>
    </div>
    <!--  中间条横-->
    <div class="box"></div>
    <!-- 在售课程 -->
    <div class="PERInformation-table">
      <!-- 添加表单弹窗 -->
      <!-- dialog 添加课程弹框-->
      <div>
        <el-button
          type="warning"
          v-if="selectedBar == 0"
          @click="dialogFormVisible2 = true"
          class="blt1"
          plain
          >添加课程</el-button
        >
        <el-dialog title="添加天赋课程" :visible.sync="dialogFormVisible2">
          <el-form :model="TalentedCourses">
            <el-form-item label="课程封面" :label-width="formLabelWidth">
              <div class="my-upload">
                <el-upload
                  class="avatar-uploader1"
                  :action="domain"
                  :show-file-list="false"
                  :on-success="Uploadcourintroductioncover5"
                  :data="QiNiYunL"
                  :before-upload="beforeUploadGetKeys"
                  multiple
                >
                  <img
                    height="100"
                    v-if="TalentedCourses.face_url"
                    :src="TalentedCourses.face_url"
                    class="avatars"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon">
                    <img src="" alt="" />
                  </i>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="课程类目" :label-width="formLabelWidth">
              <el-input
                v-model="TalentedCourses.categories"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="标题" :label-width="formLabelWidth">
              <el-input
                v-model="TalentedCourses.title"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="使用期限（月）" :label-width="formLabelWidth">
              <el-input
                v-model="TalentedCourses.duration"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="课节" :label-width="formLabelWidth">
              <el-input
                v-model="TalentedCourses.course_num"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="套餐价格" :label-width="formLabelWidth">
              <el-input
                v-model="TalentedCourses.amout"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="活动价格" :label-width="formLabelWidth">
              <el-input
                v-model="TalentedCourses.discount_amout"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程简介" :label-width="formLabelWidth">
              <el-input
                v-model="TalentedCourses.introduction_content"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="图片简介" :label-width="formLabelWidth">
              <el-input
                v-model="TalentedCourses.introduction_url"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible2 = false">取 消</el-button>
            <el-button type="primary" @click="TalentedCoursesChange()"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
      <!-- list主体表单 -->

      <div class="ontents" v-if="selectedBar == 0">
        <div class="topup-title">课程列表</div>
      </div>
      <div class="contentList">
        <div v-if="selectedBar == 0" class="paging">
          <el-table
            border
            ref="multipleTable"
            :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
            :data="recording"
            tooltip-effect="dark"
            style="width: 100%"
            @filter-change="filterChange"
          >
            <el-table-column
              prop="title"
              align="center"
              label="课程标题"
            ></el-table-column>

            <el-table-column
              prop="type"
              align="center"
              label="课程类型"
            ></el-table-column>
            <el-table-column
              prop="type"
              align="center"
              label="排课类型"
            ></el-table-column>
            <el-table-column
              prop="course_num"
              align="center"
              label="课时数"
            ></el-table-column>
            <el-table-column
              prop="create_time"
              align="center"
              label="创建时间"
            ></el-table-column>

            <el-table-column align="center" fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="changego(scope.row)"
                  type="text"
                  size="small"
                  v-if="scope.row.is_recommend == 0"
                  >参加活动</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!--分页-->
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Selectcourse",
  data() {
    return {
      id: JSON.parse(this.$route.query.id),
      // 选中的
      options: [
        {
          value: "口才",
          label: "口才",
        },
        {
          value: "乐器",
          label: "乐器",
        },
        {
          value: "唱歌",
          label: "唱歌",
        },
        {
          value: "美术",
          label: "美术",
        },
        {
          value: "书法",
          label: "书法",
        },
        {
          value: "表演",
          label: "表演",
        },
        {
          value: "游泳",
          label: "游泳",
        },
        {
          value: "乒乓球",
          label: "乒乓球",
        },
        {
          value: "羽毛球",
          label: "羽毛球",
        },
        {
          value: "轮滑",
          label: "轮滑",
        },
        {
          value: "篮球",
          label: "篮球",
        },
        {
          value: "足球",
          label: "足球",
        },
        {
          value: "高尔夫",
          label: "高尔夫",
        },
        {
          value: "象棋",
          label: "象棋",
        },
        {
          value: "围棋",
          label: "围棋",
        },
        {
          value: "乐高",
          label: "乐高",
        },
        {
          value: "编程",
          label: "编程",
        },
        {
          value: "语文",
          label: "语文",
        },
        {
          value: "数学",
          label: "数学",
        },
        {
          value: "英语",
          label: "英语",
        },
      ],
      value: "",
      // 添加课程
      // 图片上传
      domain: "http://up-z0.qiniup.com",
      upload_qiniu_addr: "http://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: "",
      },
      // 弹框表单
      labelPosition: "right",
      ruleForm: {
        contact_telephone: "",
        contacts: "",
        contacts_title: "",
        introduction_content: "",
        status: "2",
        introduction_pic: "",
        latitude: "30.275923",
        longitude: "120.232304",
        mechanism_addr: "",
        mechanism_advantage: "",
        mechanism_language: "英语",
        mechanism_logo: "",
        mechanism_name: "",
        mechanism_telephone: "",
        support_means: "",
        user_id: 0,
        type: "teach_paypal",
        recommender_id: "",
        categories: "",
        categories_child: "",
        payee_logon_id: "",
        is_support_teach_paypal: "",
        facade_view: "",
      },
      selectedBar: 0,
      recording: [], //课程数据
      pageSize: 10,
      currentPage: 1,
      total: 0,
      //   添加表单弹窗
      dialogFormVisible1: false, //修改资料
      dialogFormVisible: false, //课程类型1
      dialogFormVisible2: false, //课程类型2
      dialog: false, //选择类型
      dialog2: false, //添加老师
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "120px",
      // 选中的
      TalentedCourses: {
        amout: 0, //价格
        appointment_type: "appointment", //排课类型
        course_num: 1, //课程数
        categories: "", //类目
        face_url: "", //封面
        introduction_content: "1", //课程简介
        introduction_url: "", //图片简介
        label: "1", //标签
        title: "1", //标题
        duration: 0, //使用期限 有效期
        discount_amout: 0, //默认折扣价
      }, //天赋课程
    };
  },
  mounted() {
    this.handleCurrentChange(1);
    this.gettoken(); // 请求token
  },
  methods: {
    changego(row) {
      //参加活动
      this.$router.push({
        name: "InstitutionalEventsManagement",
        query: {
          id: JSON.stringify(row),
        },
      });
    },

    //添加天赋课程
    TalentedCoursesChange() {
      let url =
        this.$store.state.urlName +
        "/user/masterSetPrice/insertActivityMasterSetPrice";
      let data = {
        amout: this.TalentedCourses.amout,
        appointment_type: this.TalentedCourses.appointment_type,
        connect_peoplenum: 10,
        face_url: this.TalentedCourses.face_url,
        introduction_content: this.TalentedCourses.introduction_content,
        introduction_url:
          "http://img.curiousmore.com/022-AC1927D25BCF/Documents/com_tencent_imsdk_data/image/huihe_493497992,http://img.curiousmore.com/022-AC1927D25BCF/Documents/com_tencent_imsdk_data/image/huihe_771297996",
        label: this.TalentedCourses.label,
        mechanism_id: this.id.id,
        status: 1,
        titile_url: "1",
        title: this.TalentedCourses.title,
        type: "experience_specials",
        discount_amout: this.TalentedCourses.discount_amout,
        duration: this.TalentedCourses.duration,
        default_discount_price: this.TalentedCourses.discount_amout,
      };

      this.axios.post(url, data).then((res) => {
      });
      this.dialogFormVisible2 = false;
    },

    //图片操作
    // 机构logo图片
    beforeUploadGetKeys(file) {
      this.gettoken();
    },
    Uploadcourintroductioncover(file) {
      this.id.mechanism_logo = this.upload_qiniu_addr + file.key;
    },
    // 机构营业执照图片
    Uploadcourintroductioncover1(file) {
      this.id.support_means = this.upload_qiniu_addr + file.key;
    },
    // 机构门面图片
    Uploadcourintroductioncover2(file) {
      this.id.facade_view = this.upload_qiniu_addr + file.key;
    },
    // 机构其他图片
    Uploadcourintroductioncover3(file) {
      this.id.introduction_pic = this.upload_qiniu_addr + file.key;
    },

    //添加天赋课程封面
    Uploadcourintroductioncover5(file) {
      this.TalentedCourses.face_url = this.upload_qiniu_addr + file.key;
    },

    // 获取token
    gettoken() {
      let _this = this;
      var timestamp = new Date().getTime();
      let data = {
        key: timestamp,
        type: "pic",
      };
      _this
        .axios({
          method: "post",
          url: "http://101.37.66.151:8768/eg-api/push/upload/getToken",
          data: data,
        })
        .then((res) => {
          _this.QiNiYunL.token = res.data.data;
          _this.QiNiYunL.key = data.key;
        })
        .catch((err) => {
            
        });
    },

    // 修改资料//修改机构信息
    add() {
      this.dialogFormVisible1 = false;
      let data = {
        id: this.id.id,
        contact_telephone: this.id.contact_telephone,
        contacts: this.id.contacts,
        contacts_title: this.id.contacts_title,
        introduction_content: this.id.introduction_content,
        status: "2",
        introduction_pic: this.id.introduction_pic,
        latitude: this.id.latitude,
        longitude: this.id.longitude,
        mechanism_addr: this.id.mechanism_addr,
        mechanism_advantage: this.id.mechanism_advantage,
        mechanism_logo: this.id.mechanism_logo,
        mechanism_name: this.id.mechanism_name,
        mechanism_telephone: this.id.mechanism_telephone,
        support_means: this.id.support_means,
        type: "teach_paypal",
        categories: this.id.categories,
        categories_child: this.id.categories_child,
        payee_logon_id: this.id.payee_logon_id,
        is_support_teach_paypal: this.id.is_support_teach_paypal,
        facade_view: this.id.facade_view,
      };
      this.axios({
        method: "post",
        url: this.$store.state.urlName + "/user/mastermechanism/update",
        data: data,
      })
        .then((res) => {
          if (res.data.code == 0) {
            //成功
            this.$message({
              message: "添加成功",
              type: "success",
            });
          }
        })
        .catch((error) => {
        });
    },
    // list表单

    filterChange(filterObj) {
    },

    //课程列表
    GetList() {
      this.axios({
        method: "get",
        url: this.$store.state.urlName + "/user/masterSetPrice/queryByEs",
        params: {
          type: "mechanism_offline",
          pageSize: 10,
          currentPage: this.currentPage,
          is_support_teach_paypal: true,
          status: "",
          mechanism_id: this.id.id,
        },
      }).then((res) => {
        this.recording = res.data.data.rows;
        this.total = res.data.data.total;
      });
    },

    handleSizeChange(val) {
        
    },
    handleCurrentChange(val) {
        
      this.currentPage = val;
      this.GetList();
    },
  },
};
</script>
<style scoped lang="less">
// @import '../../../static/css/noTutop.css';

/* .PERInformation{
    background: #EAEAEA!important;
} */
/* 列表 */
.red {
  color: #000;
}

.paging {
  position: relative;
  margin-top: 60px;
  margin-bottom: 50px;
}

.paging>.el-pagination {
  text-align: right;
}

.ontents {
  margin-top: 50px;
}

.topup-title {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: #000;
  font-weight: bold;
}

/* 修改图片资料 */
.organizationimg {
  display: flex;
  flex-wrap: wrap;
  height: 150px;
}

.organizationimg span {
  width: 110px;
  height: 150px;
  margin-left: 15px;
  padding: 0;
  box-sizing: border-box;
}

.organizationimg .span {
  width: auto;
  height: 110px;
}

.organizationimg .span h3 {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.organizationimg span img {
  width: 100px;
  height: 100px;
}

/* 图片上传 */
.my-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  display: block;
}

.my-upload>.el-icon-plus:before {
  display: none;
}

.avatar-uploader1 {
  display: inline;
  width: 100px;
  height: 100px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader-icon {
  position: relative;
  color: #ffffff;
  width: 100px;
  height: 100px;
}

.PERInformation {
  position: relative;
}

/* 上架下架 */
.ul .action {
  color: #FF7300;
  font-size: 20px;
  font-family: 'MicrosoftYaHei-Bold';
  font-weight: 600;
  float: left;
}

.ul .action span {
  position: absolute;
  top: 35px;
  right: 5px;
  background-color: #FF7300;
  width: 48px;
  height: 5px;
  float: left;
  border-radius: 10px;
}

.ul li {
  position: relative;
  margin-left: 50px;
}

/* 中间条横 */
.box {
  width: 100%;
  height: 20px;
  margin-top: 100px;
  background-color: #EAEAEA;
}

/* 机构信息 */
.organization {
  padding: 0;
  /* border: 1px solid #ff6c60; */
  width: 1200px;
  height: 200px;
}

.blt {
  position: absolute;
  top: 120px;
  right: 52px;
}

.left {
  float: left; /* 左浮动 */
  width: 200px;
  height: 200px;
  /* border: 1px solid green; */
}

.left h2 {
  font-size: 20px;
  margin-top: 0;
}

.left img {
  width: 150px;
  height: 150px;
  margin-top: -5px;
  margin-left: 20px;
  overflow: hidden;
}

.right {
  position: relative;
  overflow: hidden;
  height: 200px;
  /* border: 1px solid gold; */
  word-break: break-all; /* 强制让内容换行 */
}

.right li {
  float: left;
  margin-left: 100px;
  margin-top: 20px;
  color: #434343;
}

.right li span {
  color: #b5b5b5;
  margin-left: 10px;
}

/* .organization label{
    float: left;
    margin-left: 20px;
} */
/* 机构照片 */
.picture {
  position: relative;
  width: 100%;
  height: 200px;
}

.picture h2 {
  font-size: 20px;
}

.picture1 img {
  width: 240px;
  height: 160px;
  border-radius: 19px;
}

.picture2 img {
  width: 190px;
  height: 163px;
  border-radius: 19px;
}

.picture3 img {
  position: absolute;
  width: 60%;
  height: 163px;
  margin-left: 20px;
  border-radius: 19px;
}

.picture3 {
  position: absolute;
  left: 45%;
  width: 50%;
}

.picture div {
  float: left;
  margin-right: 150px;
  color: #434343;
}

.picture1 {
  position: absolute;
  left: 0px;
  display: inline block;
}

.picture2 {
  margin-left: 300px;
}

.PERInformation-table {
  position: relative;
  margin-top: 20px;
}

.PERInformation-table h4 {
  text-align: center;
}

.PERInformation-table .blt1 {
  position: absolute;
  right: 52px;
  z-index: 100;
}
</style>